import { Language } from "../api/LanguageApi";

export function sortLanguages(languages: Language[]): Language[] {
  // Sort the languages array such that the following languages are at the top:
  // - Dutch
  // - French
  // - German
  // - English

  const languageOrder = ["NL", "FR", "DE", "EN"];
  return languages.sort((a, b) => {
    return languageOrder.indexOf(a.code) - languageOrder.indexOf(b.code);
  });
}
