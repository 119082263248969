import ApiInstance from "./ApiInstance";
import { LanguageValue } from "../types/LanguageValue";
import { CropData, ItemWithOrderResponse } from "./ItemApi";
import { Image } from "./MediaApi";
import { Language } from "./LanguageApi";

export enum TourStatus {
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
}

export interface Tour {
  title: LanguageValue[];
  description: LanguageValue[];
  status: TourStatus;
}

export interface TourCreateOrUpdate extends Tour {
  image: {
    url: string;
    crop?: CropData;
  };
  itemsWithOrder: { itemId: string; order: number }[];
  languageIds: string[];
}

export interface TourResponse extends Tour {
  id: string;
  image: Image;
  itemsWithOrder: ItemWithOrderResponse[];
  order: number;
  languages: Language[];
}

export type TourOrderData = {
  items: { tourId: string; order: number }[];
};

class TourApi {
  public async getTour(id: string): Promise<TourResponse> {
    const response = await ApiInstance.getApi().get(`/tours/${id}`);
    return response.data;
  }

  public async createTour(newTour: TourCreateOrUpdate): Promise<Tour> {
    const response = await ApiInstance.getApi().post("/tours", newTour);
    return response.data;
  }

  public async updateTour(
    id: string,
    updatedTour: TourCreateOrUpdate
  ): Promise<Tour> {
    const response = await ApiInstance.getApi().put(
      `/tours/${id}`,
      updatedTour
    );
    return response.data;
  }

  public async listTours(): Promise<TourResponse[]> {
    const response = await ApiInstance.getApi().get("/tours");
    return response.data.data;
  }

  public async deleteTour(id: string): Promise<void> {
    const response = await ApiInstance.getApi().delete(`/tours/${id}`);
    return response.data;
  }

  public async updateTourOrders(data: TourOrderData): Promise<void> {
    await ApiInstance.getApi().put("/tours/update-order", data);
  }
}

export default new TourApi();
