import { LanguageValue } from "../types/LanguageValue";
import { Config } from "../Config";
import { Language } from "../api/LanguageApi";

export function findLanguageValueWithDefaultLanguage(
  items: LanguageValue[],
  languages: Language[]
) {
  return (
    items.find(
      (v: LanguageValue) =>
        v.languageId ===
          languages.find((l) => l.code === Config.getDefaultLanguageCode())
            ?.id || ""
    )?.value || ""
  );
}
