import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { routes } from "../../constants/routes";
import { ItemForm } from "../../components/item-form/ItemForm";
import ItemApi from "../../api/ItemApi";
import { BackButton } from "../../components/back-button/BackButton";

export function CreateItem() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="create-item-wrapper center-wrapper">
      <div className="create-item page-width">
        <BackButton to={routes.items} />
        <h1>{t("item.new")}</h1>
        <ItemForm
          onSubmit={async (newItem) => {
            try {
              await ItemApi.createItem(newItem);
              toast.success(t("item.created"));
              navigate(routes.items);
            } catch (e) {
              toast.error(t("item.failedToCreate"));
            }
          }}
        />
      </div>
    </div>
  );
}
