import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import "./index.css";
import "./fonts/fonts.css";
import "ag-grid-community/styles//ag-grid.css";
import "ag-grid-community/styles//ag-theme-alpine.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Config } from "./Config";

Sentry.init({
  dsn: Config.getSentryDsn(),
  integrations: [],
  beforeSend: (event, hint) => {
    if (Config.isDebug()) {
      // we log `hint` to console so the original exception is printed
      // eslint-disable-next-line no-console
      console.error(hint.originalException || hint.syntheticException);
      return null; // this drops the event and nothing will be send to sentry
    }
    return event;
  },
  beforeSendTransaction: (transaction) =>
    Config.isDebug() ? null : transaction,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
