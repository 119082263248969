import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { FaPencilAlt, FaTrash, FaCrop } from "react-icons/fa";
import { AgGridReact } from "ag-grid-react";
import {
  CellClickedEvent,
  ColDef,
  GridApi,
  ValueGetterParams,
} from "ag-grid-community";
import MediaApi, { Image } from "../../api/MediaApi";
import LanguageApi, { Language } from "../../api/LanguageApi";
import {
  CropData,
  ItemCreateOrUpdate,
  ItemResponse,
  MultimediaCreateOrUpdate,
  MultimediaType,
} from "../../api/ItemApi";
import { LanguageValue } from "../../types/LanguageValue";
import { Button } from "../button/Button";
import { Input } from "../input/Input";
import { LanguageSelect } from "../language-select/LanguageSelect";
import { FileUpload } from "../file-upload/FileUpload";
import Dialog from "../dialog/Dialog";
import { MultiMediaUpload } from "../multimedia-upload/MultiMediaUpload";
import { LoadingSpinner } from "../loading-spinner/LoadingSpinner";
import "./ItemForm.css";
import { Textarea } from "../textarea/Textarea";
import { getMultimediaTypeForFile, virtualFileFromUrl } from "../../util/files";
import { ImageCropperDialog } from "../image-cropper-dialog/ImageCropperDialog";
import ImageCrop from "../image-crop/ImageCrop";
import { sortLanguages } from "../../util/orderLanguages";

const ITEM_CROP_ASPECT_RATIO = 12 / 9;

interface MultiMediaRowItem {
  type: MultimediaType;
  file: File;
  url: string;
  highlighted: boolean;
  languages: Language[];
  cropData?: CropData;
}

export function ItemForm({
  onSubmit,
  item,
}: {
  onSubmit?: (newItem: ItemCreateOrUpdate) => void;
  item?: ItemResponse | null;
}) {
  const { t } = useTranslation();

  const [currentLanguage, setCurrentLanguage] = useState<Language | null>(null);
  const [languages, setLanguages] = useState<Language[]>([]);

  const [title, setTitle] = useState<LanguageValue[]>([]);
  const [description, setDescription] = useState<LanguageValue[]>([]);
  const [location, setLocation] = useState<LanguageValue[]>([]);
  const [thanksTo, setThanksTo] = useState<LanguageValue[]>([]);
  const [artist, setArtist] = useState<string>("");

  const [highlightVideoFile, setHighlightVideoFile] = useState<
    {
      file: File;
      languageId: string;
    }[]
  >([]);

  const [showImageOrVideoDialog, setShowImageOrVideoDialog] = useState(false);
  const [showAudioDialog, setShowAudioDialog] = useState(false);
  const [showLanguageDialog, setShowLanguageDialog] = useState<boolean>(false);
  const [editMultimediaItemUrl, setEditMultimediaItemUrl] =
    useState<string>("");
  const [editMultimediaItemLanguages, setEditMultimediaItemLanguages] =
    useState<Language[]>([]);
  const submitLanguageDialogDisabled = editMultimediaItemLanguages.length === 0;

  const [imageOrVideoRowData, setImageOrVideoRowData] = useState<
    MultiMediaRowItem[]
  >([]);
  const [audioRowData, setAudioRowData] = useState<MultiMediaRowItem[]>([]);

  const [isImageCropperOpen, setIsImageCropperOpen] = useState(false);
  const [cropImage, setCropImage] = useState<Image | null>(null);

  const imageOrVideoGridRef = useRef<GridApi<MultiMediaRowItem>>();
  const audioGridRef = useRef<GridApi<MultiMediaRowItem>>();

  const columnDefs: ColDef<MultiMediaRowItem>[] = [
    {
      headerName: "Preview",
      cellRenderer: (params: ValueGetterParams<MultiMediaRowItem, any>) => {
        if (params.data === undefined) {
          return "";
        }
        if (params.data.type === MultimediaType.IMAGE) {
          return (
            <ImageCrop
              imageUrl={params.data.url}
              cropX={params.data.cropData?.x ?? 0}
              cropY={params.data.cropData?.y ?? 0}
              cropWidth={params.data.cropData?.width ?? 100}
              cropHeight={params.data.cropData?.height ?? 100}
              aspectRatio={ITEM_CROP_ASPECT_RATIO}
            />
          );
        } else if (params.data.type === MultimediaType.VIDEO) {
          return (
            <video
              controls
              src={params.data.url}
              style={{ maxHeight: "80px" }}
              autoPlay={false}
            />
          );
        } else {
          return (
            <audio controls>
              <source className={"thumbnail"} src={params.data.url} />
            </audio>
          );
        }
      },
      field: "file",
      sortable: false,
      filter: false,
      resizable: true,
      rowDrag: true,
      flex: 1,
    },
    {
      headerName: t("item.languages"),
      valueGetter: (params: ValueGetterParams<MultiMediaRowItem, any>) => {
        if (params.data === undefined) {
          return "";
        }
        return params.data.languages.map((l: Language) => l.code).join(", ");
      },
      sortable: false,
      filter: false,
      resizable: false,
      flex: 0.8,
      field: "languages",
    },
    {
      cellRenderer: (params: ValueGetterParams<MultiMediaRowItem, any>) => {
        return params.data?.type === MultimediaType.IMAGE ? (
          <FaCrop className="cursor-pointer" />
        ) : null;
      },
      flex: 0.1,
      sortable: false,
      filter: false,
      resizable: false,
      field: "type",
      headerName: "",
      onCellClicked: (event: CellClickedEvent<MultiMediaRowItem, any>) => {
        if (
          event.data === undefined ||
          event.data === null ||
          event.data.type !== MultimediaType.IMAGE
        ) {
          return;
        }

        if (event.data.cropData) {
          setCropImage({
            id: "",
            originalUrl: event.data.url,
            cropX: event.data.cropData.x,
            cropY: event.data.cropData.y,
            cropWidth: event.data.cropData.width,
            cropHeight: event.data.cropData.height,
          });
          setIsImageCropperOpen(true);
        }
      },
    },
    {
      cellRenderer: () => {
        return <FaPencilAlt className="cursor-pointer" />;
      },
      flex: 0.1,
      sortable: false,
      filter: false,
      resizable: false,
      field: "type",
      headerName: "",
      onCellClicked: (event: CellClickedEvent<MultiMediaRowItem, any>) => {
        if (event.data === undefined || event.data === null) {
          return;
        }
        setEditMultimediaItemLanguages(event.data.languages);
        setEditMultimediaItemUrl(event.data.url);
        setShowLanguageDialog(true);
      },
    },
    {
      cellRenderer: () => {
        return <FaTrash className="delete-file cursor-pointer" />;
      },
      flex: 0.1,
      sortable: false,
      filter: false,
      resizable: false,
      field: "type",
      headerName: "",
      onCellClicked: (event: CellClickedEvent<MultiMediaRowItem, any>) => {
        if (event.data === undefined || event.data === null) {
          return;
        }

        setImageOrVideoRowData((rowData) =>
          rowData.filter((r) => r.file.name !== event.data?.file.name)
        );
        setAudioRowData((rowData) =>
          rowData.filter((r) => r.file.name !== event.data?.file.name)
        );
      },
    },
  ];

  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    LanguageApi.getLanguages().then((languages) => {
      const sortedLanguages = sortLanguages(languages);
      if (sortedLanguages.length > 0) {
        setCurrentLanguage(sortedLanguages[0]);

        for (const language of sortedLanguages) {
          setTitle((title) => {
            title.push({
              languageId: language.id,
              value: "",
            });
            return title;
          });

          setDescription((description) => {
            description.push({
              languageId: language.id,
              value: "",
            });
            return description;
          });

          setLocation((location) => {
            location.push({
              languageId: language.id,
              value: "",
            });
            return location;
          });

          setThanksTo((thanksTo) => {
            thanksTo.push({
              languageId: language.id,
              value: "",
            });
            return thanksTo;
          });
        }
      }
      setLanguages(sortedLanguages);
    });
  }, []);

  useEffect(() => {
    if (item) {
      setTitle(item.title);
      setDescription(item.description);
      setLocation(item.location);
      setThanksTo(item.thanksTo);
      setArtist(item.artist);

      setHighlightVideoFile(
        item.multimedia
          .filter((m) => m.highlighted && m.type === MultimediaType.VIDEO)
          .map((el) => {
            return {
              file: virtualFileFromUrl(el.url),
              languageId: el.languages[0].id,
            };
          })
      );

      setImageOrVideoRowData(
        item.multimedia
          .filter(
            (m) => m.type !== MultimediaType.AUDIO_FRAGMENT && !m.highlighted
          )
          .map((m) => ({
            type: m.type,
            file: virtualFileFromUrl(m.url),
            cropData:
              m.type === MultimediaType.IMAGE
                ? {
                    x: m.image?.cropX ?? 0,
                    y: m.image?.cropY ?? 0,
                    width: m.image?.cropWidth ?? 100,
                    height: m.image?.cropHeight ?? 100,
                    aspectRatio: ITEM_CROP_ASPECT_RATIO,
                  }
                : undefined,
            url: m.url,
            highlighted: m.highlighted,
            languages: m.languages,
          }))
      );

      setAudioRowData(
        item.multimedia
          .filter((m) => m.type === MultimediaType.AUDIO_FRAGMENT)
          .map((m) => ({
            type: m.type,
            file: virtualFileFromUrl(m.url),
            url: m.url,
            highlighted: m.highlighted,
            languages: m.languages,
          }))
      );
    }
  }, [item]);

  if (!currentLanguage) {
    return null;
  }

  function rowDataToMultiMedia(
    gridApi: GridApi<MultiMediaRowItem> | undefined
  ): MultimediaCreateOrUpdate[] {
    if (!gridApi) {
      return [];
    }

    const multiMedia: MultimediaCreateOrUpdate[] = [];
    gridApi.forEachNode((node, i) => {
      if (!node.data) {
        return;
      }

      const { type, url, highlighted } = node.data;
      multiMedia.push({
        type,
        url,
        highlighted,
        languageIds: node.data.languages.map((language) => language.id),
        order: i,
        crop: node.data.cropData,
      });
    });
    return multiMedia;
  }

  async function onSubmitItem() {
    if (!allInputsHaveValue()) {
      toast.warning(t("general.notAllFields"));
      return;
    }

    if (onSubmit) {
      const highlightedMultimedia: MultimediaCreateOrUpdate[] = [];
      for (const f of highlightVideoFile) {
        let highlightVideoUrl =
          item?.multimedia.find(
            (e) =>
              e.highlighted &&
              e.type === MultimediaType.VIDEO &&
              e.languages[0].id === f.languageId
          )?.url ?? "";

        if (!f.file.name.startsWith("http")) {
          // highlight video has changed, upload new one
          setShowLoading(true);
          highlightVideoUrl = await MediaApi.uploadMedia(f.file);
          setShowLoading(false);
        }
        highlightedMultimedia.push({
          type: MultimediaType.VIDEO,
          url: highlightVideoUrl,
          highlighted: true,
          languageIds: [f.languageId],
        });
      }

      const imageOrVideoMultiMedia = rowDataToMultiMedia(
        imageOrVideoGridRef.current
      );
      const audioMultimedia = rowDataToMultiMedia(audioGridRef.current);
      const multimedia = [
        ...imageOrVideoMultiMedia,
        ...audioMultimedia,
        ...highlightedMultimedia,
      ];
      onSubmit({
        title,
        description,
        location,
        artist,
        thanksTo,
        multimedia,
      });
    }
  }

  function allInputsHaveValue() {
    for (const language of languages) {
      for (const value of title) {
        if (value.languageId === language.id && !value.value) {
          return false;
        }
      }

      for (const value of location) {
        if (value.languageId === language.id && !value.value) {
          return false;
        }
      }
    }

    return true;
  }

  // page with back button in top left corner
  return (
    <div className="item-form-wrapper">
      <Input
        className="mb-4"
        type="text"
        required
        value={artist}
        placeholder={t("item.artist")}
        onChange={(value) => setArtist(value)}
        showAsterisk
        form="item-form"
      />
      <div className="images-and-video mb-4">
        <div className="images-and-video-label mb-2">
          {t("item.imagesAndVideos")}
        </div>
        <div className="images-and-video-table mm-table ag-theme-alpine">
          {languages.length > 0 ? (
            <AgGridReact
              columnDefs={columnDefs}
              rowData={imageOrVideoRowData}
              animateRows
              rowDragManaged
              onGridReady={(params) => {
                imageOrVideoGridRef.current = params.api;
              }}
              gridOptions={{ rowHeight: 100 }}
            />
          ) : null}
        </div>
        <Button
          className="mt-4 ml-auto"
          onClick={() => setShowImageOrVideoDialog(true)}
        >
          {t("item.upload")}
        </Button>
      </div>
      <div className="audio mb-4">
        <div className="audio-label mb-2">{t("item.audio")}</div>
        <div className="audio-table mm-table ag-theme-alpine">
          {languages.length > 0 ? (
            <AgGridReact
              columnDefs={columnDefs}
              rowData={audioRowData}
              animateRows
              rowDragManaged
              onGridReady={(params) => {
                audioGridRef.current = params.api;
              }}
            />
          ) : null}
        </div>
        <Button
          className="mt-4 ml-auto"
          onClick={() => setShowAudioDialog(true)}
        >
          {t("item.upload")}
        </Button>
      </div>
      <LanguageSelect
        className="mb-4"
        languages={languages}
        currentLanguage={currentLanguage}
        setCurrentLanguage={setCurrentLanguage}
      />
      <form
        id="item-form"
        className="item-form"
        onSubmit={(e) => {
          e.preventDefault();
          onSubmitItem();
        }}
      >
        <FileUpload
          label={t("item.highlightedVideo")}
          className="mb-4"
          accept="video/*"
          file={
            highlightVideoFile.find((t) => t.languageId === currentLanguage?.id)
              ?.file || null
          }
          setFile={(file: File) => {
            const newHighlightedVideoFile = highlightVideoFile.filter(
              (f) => f.languageId !== currentLanguage.id
            );
            newHighlightedVideoFile.push({
              languageId: currentLanguage.id,
              file: file,
            });
            setHighlightVideoFile(newHighlightedVideoFile);
          }}
          required={false}
          preview={true}
          fileType={MultimediaType.VIDEO}
          canDelete={true}
          onDelete={() => {
            const newHighlightedVideFile = highlightVideoFile.filter(
              (f) => f.languageId !== currentLanguage.id
            );
            setHighlightVideoFile(newHighlightedVideFile);
          }}
        />
        <Input
          className="mb-2"
          type="text"
          required
          value={
            title.find((t) => t.languageId === currentLanguage.id)?.value ?? ""
          }
          placeholder={t("tour.title")}
          onChange={(value) => {
            const newTitle = title.filter(
              (t) => t.languageId !== currentLanguage.id
            );
            newTitle.push({
              languageId: currentLanguage.id,
              value,
            });
            setTitle(newTitle);
          }}
          showAsterisk
        />
        <Textarea
          className="description mt-4"
          required={false}
          value={
            description.find((t) => t.languageId === currentLanguage.id)
              ?.value ?? ""
          }
          placeholder={t("item.description")}
          onChange={(value) => {
            const newDescription = description.filter(
              (t) => t.languageId !== currentLanguage.id
            );
            newDescription.push({
              languageId: currentLanguage.id,
              value,
            });
            setDescription(newDescription);
          }}
        />
        <Input
          className="mt-4"
          type="text"
          required
          value={
            location.find((t) => t.languageId === currentLanguage.id)?.value ??
            ""
          }
          placeholder={t("item.location")}
          onChange={(value) => {
            const newLocation = location.filter(
              (t) => t.languageId !== currentLanguage.id
            );
            newLocation.push({
              languageId: currentLanguage.id,
              value,
            });
            setLocation(newLocation);
          }}
          showAsterisk
        />
        <Input
          className="mt-4"
          type="text"
          value={
            thanksTo.find((t) => t.languageId === currentLanguage.id)?.value ??
            ""
          }
          placeholder={t("item.thanksTo")}
          onChange={(value) => {
            const newThanksTo = thanksTo.filter(
              (t) => t.languageId !== currentLanguage.id
            );
            newThanksTo.push({
              languageId: currentLanguage.id,
              value,
            });
            setThanksTo(newThanksTo);
          }}
        />

        <Button type="submit" color="primary" className="mt-4 ml-auto">
          {t("general.save")}
        </Button>
      </form>
      <Dialog
        isOpen={showImageOrVideoDialog}
        onRequestClose={() => setShowImageOrVideoDialog(false)}
        title={t("item.uploadImageOrVideo")}
        size="xsmall"
        shouldCloseOnOverlayClick={false}
      >
        <MultiMediaUpload
          isImageOrVideo
          languages={languages}
          onSave={(file, url, languages) => {
            const type = getMultimediaTypeForFile(file);
            setImageOrVideoRowData((rowData) => [
              ...rowData,
              {
                type,
                file,
                highlighted: false,
                languages,
                url,
                cropData:
                  type === MultimediaType.IMAGE
                    ? {
                        x: cropImage?.cropX ?? 0,
                        y: cropImage?.cropY ?? 0,
                        width: cropImage?.cropWidth ?? 100,
                        height: cropImage?.cropHeight ?? 100,
                        aspectRatio: ITEM_CROP_ASPECT_RATIO,
                      }
                    : undefined,
              },
            ]);

            setShowImageOrVideoDialog(false);
          }}
        />
      </Dialog>
      <Dialog
        isOpen={showAudioDialog}
        onRequestClose={() => setShowAudioDialog(false)}
        title={t("item.uploadAudio")}
        size="xsmall"
        shouldCloseOnOverlayClick={false}
      >
        <MultiMediaUpload
          isImageOrVideo={false}
          languages={languages}
          onSave={(file, url, languages) => {
            setAudioRowData((rowData) => [
              ...rowData,
              {
                type: MultimediaType.AUDIO_FRAGMENT,
                file,
                highlighted: false,
                languages,
                url,
              },
            ]);

            setShowAudioDialog(false);
          }}
        />
      </Dialog>
      <Dialog
        isOpen={showLanguageDialog}
        onRequestClose={() => {
          setShowLanguageDialog(false);
          setEditMultimediaItemUrl("");
          setEditMultimediaItemLanguages([]);
        }}
        title={t("item.updateLanguages")}
        size="xsmall"
      >
        {editMultimediaItemUrl !== "" ? (
          <div>
            <div>
              {" "}
              {languages.map((language) => (
                <div key={language.id} className="item">
                  <input
                    className="form-check-input mr-2"
                    type="checkbox"
                    id={language.id}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setEditMultimediaItemLanguages([
                          ...editMultimediaItemLanguages,
                          language,
                        ]);
                      } else {
                        setEditMultimediaItemLanguages(
                          editMultimediaItemLanguages.filter(
                            (l) => l.id !== language.id
                          )
                        );
                      }
                    }}
                    checked={editMultimediaItemLanguages.some(
                      (sl) => sl.id === language.id
                    )}
                  />
                  <label className="form-check-label" htmlFor={language.id}>
                    {language.name}
                  </label>
                </div>
              ))}
            </div>
            <Button
              type="button"
              color="primary"
              className={`mt-4 ml-auto ${
                submitLanguageDialogDisabled ? "disabled" : ""
              }`}
              onClick={() => {
                setImageOrVideoRowData(
                  imageOrVideoRowData.map((el) => {
                    if (el.url === editMultimediaItemUrl) {
                      el.languages = editMultimediaItemLanguages;
                      return el;
                    }
                    return el;
                  })
                );
                setAudioRowData(
                  audioRowData.map((el) => {
                    if (el.url === editMultimediaItemUrl) {
                      el.languages = editMultimediaItemLanguages;
                      return el;
                    }
                    return el;
                  })
                );
                setShowLanguageDialog(false);
                setEditMultimediaItemLanguages([]);
                setEditMultimediaItemUrl("");
              }}
              disabled={submitLanguageDialogDisabled}
            >
              {t("item.update")}
            </Button>
          </div>
        ) : null}
      </Dialog>
      {cropImage !== null && (
        <ImageCropperDialog
          isOpen={isImageCropperOpen && cropImage !== null}
          setIsOpen={setIsImageCropperOpen}
          aspectRatio={ITEM_CROP_ASPECT_RATIO}
          onSave={(crop) => {
            setImageOrVideoRowData(
              imageOrVideoRowData.map((el) => {
                if (el.url === cropImage?.originalUrl) {
                  el.cropData = {
                    ...crop,
                    aspectRatio: ITEM_CROP_ASPECT_RATIO,
                  };
                  return el;
                }

                return el;
              })
            );

            setCropImage(null);
          }}
          imageUrl={cropImage.originalUrl}
          initialCropData={{
            x: cropImage.cropX,
            y: cropImage.cropY,
            width: cropImage.cropWidth,
            height: cropImage.cropHeight,
            aspectRatio: ITEM_CROP_ASPECT_RATIO,
          }}
          onRequestClose={() => {
            setCropImage(null);
          }}
        />
      )}
      {showLoading && <LoadingSpinner />}
    </div>
  );
}
