import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Language } from "../../api/LanguageApi";
import MediaApi from "../../api/MediaApi";
import { Button } from "../button/Button";
import { FileUpload } from "../file-upload/FileUpload";
import { LoadingSpinner } from "../loading-spinner/LoadingSpinner";
import { Image } from "../../api/MediaApi";
import "./MultiMediaUpload.css";

export function MultiMediaUpload({
  onSave,
  isImageOrVideo,
  languages,
}: {
  onSave: (
    file: File,
    url: string,
    languages: Language[],
    image?: Image
  ) => void;
  isImageOrVideo: boolean;
  languages: Language[];
}) {
  const { t } = useTranslation();
  const [file, setFile] = useState<File | null>(null);
  const [selectedLanguages, setSelectedLanguages] =
    useState<Language[]>(languages); // all languages are selected by default
  const [showLoading, setShowLoading] = useState(false);

  const submitDisabled = selectedLanguages.length === 0 || file === null;

  return (
    <div className="multimedia-upload">
      <FileUpload
        label={isImageOrVideo ? t("item.imageOrVideo") : t("item.audioFile")}
        className="mb-4"
        accept={isImageOrVideo ? "image/*,video/*" : "audio/*"}
        file={file}
        setFile={setFile}
        required
        preview={true}
      />
      <div className="language-multiselect">
        <div className="language-multiselect-title mb-2">
          {t("item.languages")}
        </div>
        {languages.map((language) => (
          <div key={language.id} className="item">
            <input
              className="form-check-input mr-2"
              type="checkbox"
              id={language.id}
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedLanguages([...selectedLanguages, language]);
                } else {
                  setSelectedLanguages(
                    selectedLanguages.filter((l) => l.id !== language.id)
                  );
                }
              }}
              checked={selectedLanguages.some((sl) => sl.id === language.id)}
            />
            <label className="form-check-label" htmlFor={language.id}>
              {language.name}
            </label>
          </div>
        ))}
      </div>
      <Button
        type="button"
        color="primary"
        className={`mt-4 ml-auto ${submitDisabled ? "disabled" : ""}`}
        onClick={() => {
          if (!file) {
            toast.warning(t("item.selectFile"));
            return;
          }

          setShowLoading(true);

          MediaApi.uploadMedia(file)
            .then((url) => {
              onSave(file, url, selectedLanguages);
              setShowLoading(false);
            })
            .catch(() => {
              setShowLoading(false);
              toast.error(t("item.failedToUpload"));
            });
        }}
        disabled={submitDisabled}
      >
        {t("item.upload")}
      </Button>
      {showLoading && <LoadingSpinner />}
    </div>
  );
}
