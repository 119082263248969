import React, { useState } from "react";
import ReactCrop, { Crop } from "react-image-crop";
import Dialog from "../dialog/Dialog";
import { Button } from "../button/Button";
import "react-image-crop/dist/ReactCrop.css";
import "./ImageCropperDialog.css";
import { useTranslation } from "react-i18next";
import { CropData } from "../../api/ItemApi";

export function ImageCropperDialog({
  isOpen,
  setIsOpen,
  initialCropData = {
    x: 0,
    y: 0,
    width: 100,
    height: 100,
    aspectRatio: 12 / 9,
  },
  imageUrl,
  onSave,
  aspectRatio,
  onRequestClose,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  initialCropData?: CropData;
  imageUrl: string;
  onSave: (crop: Crop) => void;
  aspectRatio?: number;
  onRequestClose?: () => void;
}) {
  const { t } = useTranslation();

  const [crop, setCrop] = useState<Crop>({
    ...initialCropData,
    unit: "%",
  });

  function closeDialog() {
    setIsOpen(false);

    setCrop({
      ...initialCropData,
      unit: "%",
    });

    if (onRequestClose) {
      onRequestClose();
    }
  }

  return (
    <Dialog
      className="image-cropper-dialog"
      title={t("imageCropper.cropImage")}
      isOpen={isOpen}
      onRequestClose={closeDialog}
      size="large"
      shouldCloseOnOverlayClick={false}
    >
      <ReactCrop
        crop={crop}
        onChange={(_, percentCrop) => setCrop(percentCrop)}
        aspect={aspectRatio}
      >
        <img src={imageUrl} className="cropper-image" />
      </ReactCrop>
      <div className="flex ml-auto mt-4">
        <Button onClick={closeDialog} color="secondary" className="mr-4">
          {t("general.cancel")}
        </Button>
        <Button
          onClick={() => {
            onSave(crop);
            setIsOpen(false);
          }}
        >
          {t("general.save")}
        </Button>
      </div>
    </Dialog>
  );
}
