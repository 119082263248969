import ApiInstance from "./ApiInstance";

export type Language = {
  id: string;
  name: string;
  code: string;
};

class LanguageApi {
  public async getLanguages(): Promise<Language[]> {
    const response = await ApiInstance.getApi().get("/languages");
    return response.data;
  }
}

export default new LanguageApi();
