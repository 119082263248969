import { MultimediaType } from "../api/ItemApi";

/**
 * Create a virtual file from a url, virtual in the sense that the file
 * has no real content, but only a name.
 **/
export function virtualFileFromUrl(url: string): File {
  const blob = new Blob([]);
  return new File([blob], url);
}

export function getFilenameFromUrl(url: string) {
  return url.split("/").pop() ?? "file";
}

export function getMultimediaTypeForFile(file: File): MultimediaType {
  const fileType = file.type.split("/")[0];
  switch (fileType) {
    case "image":
      return MultimediaType.IMAGE;
    case "video":
      return MultimediaType.VIDEO;
    case "audio":
      return MultimediaType.AUDIO_FRAGMENT;
    default:
      throw new Error(`Unknown file type: ${fileType}`);
  }
}
