import React from "react";
import "./LoadingSpinner.css";

export function LoadingSpinner() {
  return (
    <div className="loading-spinner-overlay">
      <div className="spinner"></div>
    </div>
  );
}
