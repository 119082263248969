import React from "react";
import { Link } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import "./BackButton.css";

export function BackButton({
  to,
  className,
}: {
  to: string;
  className?: string;
}) {
  const { t } = useTranslation();
  return (
    <Link to={to} className={`back-button ${className}`}>
      <FaArrowLeft className="mr-2" />
      <span>{t("general.back")}</span>
    </Link>
  );
}
