import React, { useEffect, useRef, useState } from "react";
import "./Items.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/button/Button";
import TourApi, { TourResponse } from "../../api/TourApi";
import LanguageApi, { Language } from "../../api/LanguageApi";
import { AgGridReact } from "ag-grid-react";
import {
  ColDef,
  ValueGetterParams,
  GridApi,
  CellClickedEvent,
} from "ag-grid-community";
import { routes } from "../../constants/routes";
import ItemApi, { ItemResponse } from "../../api/ItemApi";
import { FaPencilAlt, FaTrash } from "react-icons/fa";
import Dialog from "../../components/dialog/Dialog";
import { toast } from "react-toastify";
import { findLanguageValueWithDefaultLanguage } from "../../components/findLanguageValueWithDefaultLanguage";

export function Items() {
  const { t } = useTranslation();

  const [languages, setLanguages] = useState<Language[]>([]);
  const [items, setItems] = useState<ItemResponse[]>([]);
  const [tours, setTours] = useState<TourResponse[]>([]);

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [deletingItemId, setDeletingItemId] = useState<string>("");

  const navigate = useNavigate();

  useEffect(() => {
    LanguageApi.getLanguages().then((l) => setLanguages(l));
    ItemApi.listItems().then((t) => {
      setItems(t);
    });
    TourApi.listTours().then((t) => {
      setTours(t);
    });
  }, []);

  const itemsGridRef = useRef<GridApi<ItemResponse>>();

  const columnDefs: ColDef<ItemResponse>[] = [
    {
      headerName: t("table.title"),
      valueGetter: (params: ValueGetterParams<ItemResponse, any>) => {
        return findLanguageValueWithDefaultLanguage(
          params.data?.title || [],
          languages
        );
      },
      sortable: true,
      filter: true,
      resizable: true,
      field: "title",
      rowDrag: false,
      flex: 1,
    },
    {
      headerName: t("table.artist"),
      valueGetter: (params) => params.data?.artist,
      sortable: true,
      filter: true,
      field: "artist",
      resizable: true,
      flex: 1,
    },
    {
      headerName: t("table.location"),
      valueGetter: (params: ValueGetterParams<ItemResponse, any>) => {
        return findLanguageValueWithDefaultLanguage(
          params.data?.location || [],
          languages
        );
      },
      sortable: true,
      filter: true,
      resizable: true,
      field: "location",
      flex: 1,
    },
    {
      headerName: t("table.description"),
      valueGetter: (params: ValueGetterParams<ItemResponse, any>) => {
        return findLanguageValueWithDefaultLanguage(
          params.data?.description || [],
          languages
        );
      },
      sortable: true,
      filter: true,
      resizable: true,
      flex: 1,
      field: "description",
    },
    {
      headerName: t("table.tours"),
      valueGetter: (params: ValueGetterParams<ItemResponse, any>) => {
        return params.data?.itemsWithOrder.map((el: { tourId: string }) => {
          if (tours === undefined) {
            return;
          }
          const tour = tours.find((t) => t.id === el.tourId);
          if (!tour) {
            return;
          }
          return findLanguageValueWithDefaultLanguage(
            tour.title || [],
            languages
          );
        });
      },
      field: "itemsWithOrder",
      sortable: true,
      filter: true,
      resizable: true,
      flex: 1,
    },
    {
      cellRenderer: () => {
        return <FaPencilAlt className={"cell-operation"} />;
      },
      flex: 0.2,
      sortable: false,
      filter: false,
      resizable: false,
      onCellClicked: (event: CellClickedEvent<ItemResponse, any>) => {
        navigate(routes.editItem.replace(":id", event.data?.id || ""));
      },
      field: "id",
      headerName: "",
    },
    {
      cellRenderer: () => {
        return <FaTrash color={"red"} className={"cell-operation"} />;
      },
      flex: 0.2,
      sortable: false,
      filter: false,
      resizable: false,
      onCellClicked: (event: CellClickedEvent<ItemResponse, any>) => {
        if (event.data === undefined) {
          return;
        }
        setDeletingItemId(event.data.id);
        setShowDeleteModal(true);
      },
      field: "id",
      headerName: "",
    },
  ];

  function deleteItem() {
    ItemApi.deleteItem(deletingItemId)
      .then(() => {
        setItems(items.filter((i) => i.id !== deletingItemId));
      })
      .catch(() => toast.error("general.somethingWrong"));
    setDeletingItemId("");
  }

  return (
    <div className={"items center-wrapper"}>
      <div className={"items-wrapper page-width"}>
        <h1>{t("items.title")}</h1>
        <div className={"table-header"}>
          <Button
            onClick={() => {
              navigate(routes.newItem);
            }}
          >
            {t("items.create")}
          </Button>
        </div>
        <div>
          {items ? (
            <div className={"items-table ag-theme-alpine"}>
              <AgGridReact
                rowData={items}
                columnDefs={columnDefs}
                onGridReady={(params) => {
                  itemsGridRef.current = params.api;
                }}
                animateRows
              />
            </div>
          ) : null}
        </div>
      </div>
      <Dialog
        isOpen={showDeleteModal}
        onRequestClose={() => setShowDeleteModal(false)}
        title={t("items.delete")}
        size="xsmall"
      >
        <div className={"delete-item-dialog-content"}>
          <div className={"description"}>
            {t("items.deleteWarning")}
            {t("items.linkedTours", {
              count:
                items.find((i) => i.id === deletingItemId)?.itemsWithOrder
                  .length || 0,
            })}
          </div>
          <div className={"tour-listing"}>
            {items
              .find((i) => i.id === deletingItemId)
              ?.itemsWithOrder.map((el) => {
                const tour = tours.find((t) => t.id === el.tourId);
                if (!tour) {
                  return;
                }
                return findLanguageValueWithDefaultLanguage(
                  tour.title || [],
                  languages
                );
              })
              .join("\n")}
          </div>
          <div className={"button-wrapper"}>
            <Button
              color="danger"
              onClick={() => {
                deleteItem();
                setShowDeleteModal(false);
              }}
            >
              {t("general.confirm")}
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
