export const routes = {
  root: "/",
  login: "/login",
  items: "/items",
  newItem: "/items/new",
  editItem: "/items/:id/edit",
  tours: "/tours",
  newTour: "/tours/new",
  editTour: "/tours/:id/edit",
};
