import React from "react";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { routes } from "./constants/routes";
import { Login } from "./pages/login/Login";
import "./i18n";
import "./i18n";
import { ToastContainer, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RedirectIfLoggedOut } from "./components/RedirectIfLoggedOut";
import { Home } from "./pages/home/Home";
import { Header } from "./components/header/Header";
import { Tours } from "./pages/tours/Tours";
import { CreateTour } from "./pages/create-tour/CreateTour";
import { CreateItem } from "./pages/create-item/CreateItem";
import { EditTour } from "./pages/edit-tour/EditTour";
import { EditItem } from "./pages/edit-item/EditItem";
import { Items } from "./pages/items/Items";

export default function App() {
  return (
    <>
      <BrowserRouter>
        <RedirectIfLoggedOut />
        <Routes>
          <Route path={routes.login} element={<Login />} />
          <Route
            element={
              <>
                <Header />
                <Outlet />
              </>
            }
          >
            <Route path={routes.root} element={<Home />} />
            <Route path={routes.tours} element={<Tours />} />
            <Route path={routes.items} element={<Items />} />
            <Route path={routes.newTour} element={<CreateTour />} />
            <Route path={routes.editTour} element={<EditTour />} />
            <Route path={routes.newItem} element={<CreateItem />} />
            <Route path={routes.editItem} element={<EditItem />} />
          </Route>
        </Routes>
      </BrowserRouter>
      <ToastContainer
        theme={"colored"}
        transition={Flip}
        pauseOnFocusLoss={false}
        hideProgressBar={true}
        position={"bottom-right"}
        autoClose={5000}
      />
    </>
  );
}
