import ApiInstance from "./ApiInstance";
import { LocalStorage } from "../enums/LocalStorage";

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

class AuthApi {
  public async login(
    email: string,
    password: string
  ): Promise<{ token: string }> {
    const response = await ApiInstance.getApi().post("/auth/login", {
      email,
      password,
    });
    return {
      token: response.data.data.token,
    };
  }

  public logout(): void {
    localStorage.removeItem(LocalStorage.Token);
    localStorage.removeItem(LocalStorage.User);
  }
}

export default new AuthApi();
