import React from "react";
import { Language } from "../../api/LanguageApi";
import { Button } from "../button/Button";
import "./LanguageSelect.css";

export function LanguageSelect({
  className,
  languages,
  currentLanguage,
  setCurrentLanguage,
}: {
  className?: string;
  languages: Language[];
  currentLanguage: Language;
  setCurrentLanguage: (language: Language) => void;
}) {
  return (
    <div className={`language-select flex ${className}`}>
      {languages.map((language) => (
        <Button
          key={language.code}
          className="language-select-button"
          onClick={(e) => {
            e.preventDefault();
            setCurrentLanguage(language);
          }}
          color={language.id === currentLanguage.id ? "primary" : "outlined"}
        >
          {language.name}
        </Button>
      ))}
    </div>
  );
}
