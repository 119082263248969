import React from "react";
import "./Button.css";

export function Button({
  children,
  onClick,
  className,
  type,
  fullWidth = false,
  disabled = false,
  color = "primary",
}: {
  children: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  className?: string;
  type?: "button" | "submit" | "reset";
  fullWidth?: boolean;
  disabled?: boolean;
  color?: "primary" | "secondary" | "danger" | "outlined";
}) {
  return (
    <button
      className={`custom-button ${className || ""} ${
        fullWidth ? "full-width" : "fit-content"
      } ${color}`}
      onClick={(e) => {
        if (onClick) {
          onClick(e);
        }
      }}
      type={type}
      disabled={disabled}
    >
      {children}
    </button>
  );
}
