import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { TourForm } from "../../components/tour-form/TourForm";
import { routes } from "../../constants/routes";
import TourApi, { TourResponse } from "../../api/TourApi";
import { BackButton } from "../../components/back-button/BackButton";

export function EditTour() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [tour, setTour] = useState<TourResponse | null>(null);

  useEffect(() => {
    if (!id) {
      return;
    }

    TourApi.getTour(id).then((tour) => {
      setTour(tour);
    });
  }, [id]);

  if (!id) {
    navigate(routes.tours);
    return null;
  }

  return (
    <div className="edit-tour-wrapper center-wrapper">
      <div className="edit-tour page-width">
        <BackButton to={routes.tours} />
        <h1>{t("tour.editTour")}</h1>
        <TourForm
          tour={tour}
          onSubmit={async (tour) => {
            try {
              await TourApi.updateTour(id, tour);
              toast.success(t("tour.saved"));
              navigate(routes.tours);
            } catch (e) {
              toast.error(t("tour.failedToSave"));
            }
          }}
        />
      </div>
    </div>
  );
}
