import { useRef } from "react";
import "./FileUpload.css";
import { useTranslation } from "react-i18next";
import { Button } from "../button/Button";
import { CropData, MultimediaType } from "../../api/ItemApi";
import { FaTrash } from "react-icons/fa";
import ImageCrop from "../image-crop/ImageCrop";

export function FileUpload({
  label,
  required,
  file,
  fileType,
  cropData,
  setFile,
  initialFilename,
  accept,
  multiple = false,
  className,
  preview = false,
  canDelete = false,
  onDelete,
  children,
}: {
  label: string;
  required: boolean;
  file: File | null;
  fileType?: MultimediaType | null;
  cropData?: CropData;
  setFile: (value: File) => void;
  initialFilename?: string;
  accept: string;
  multiple?: boolean;
  className?: string;
  preview?: boolean;
  canDelete?: boolean;
  onDelete?: () => void;
  children?: React.ReactNode;
}) {
  const ref = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  const isImage =
    (fileType && fileType === MultimediaType.IMAGE) ||
    (!fileType && file && file.type.startsWith("image/"));
  const isVideo =
    (fileType && fileType === MultimediaType.VIDEO) ||
    (!fileType && file && file.type.startsWith("video/"));
  const isAudio =
    (fileType && fileType === MultimediaType.AUDIO_FRAGMENT) ||
    (!fileType && file && file.type.startsWith("audio/"));

  const filename = file ? file.name : initialFilename ?? t("fileUpload.noFile");

  return (
    <div className={`upload-group ${className ?? ""}`}>
      <div>
        {file && preview ? (
          isImage ? (
            <ImageCrop
              imageUrl={
                file.name.startsWith("http")
                  ? file.name
                  : URL.createObjectURL(file)
              }
              cropX={cropData?.x ?? 0}
              cropY={cropData?.y ?? 0}
              cropWidth={cropData?.width ?? 100}
              cropHeight={cropData?.height ?? 100}
              aspectRatio={12 / 9}
            />
          ) : isVideo ? (
            <video
              controls
              className={"thumbnail"}
              src={
                file.name.startsWith("http")
                  ? file.name
                  : URL.createObjectURL(file)
              }
              autoPlay={false}
            />
          ) : isAudio ? (
            <audio controls>
              <source
                className={"thumbnail"}
                src={
                  file.name.startsWith("http")
                    ? file.name
                    : URL.createObjectURL(file)
                }
              />
            </audio>
          ) : null
        ) : (
          <div className="filename mr-2">{filename}</div>
        )}
        {children}
        <div className={"button-wrapper"}>
          <Button
            className="upload-button"
            onClick={(e) => {
              e.preventDefault();
              if (ref.current) {
                ref.current.click();
              }
            }}
            color="secondary"
          >
            {multiple
              ? t("fileUpload.chooseFiles")
              : t("fileUpload.chooseFile")}
          </Button>
          {canDelete && file ? (
            <FaTrash
              className={"delete-highlight"}
              color={"red"}
              onClick={onDelete}
            />
          ) : null}
        </div>
      </div>
      <input
        ref={ref}
        id="asset-upload"
        className="mt-2"
        type="file"
        accept={accept}
        onChange={(e) => {
          if (e.target.files && e.target.files.length > 0) {
            setFile(e.target.files[0]);
          }
        }}
        multiple={multiple}
      />
      <label
        className="label"
        onClick={() => {
          if (ref.current) {
            ref.current.focus();
          }
        }}
      >
        {label} {required ? "*" : ""}
      </label>
    </div>
  );
}
