import React from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/logo.png";
import { routes } from "../../constants/routes";
import "./Header.css";
import { useTranslation } from "react-i18next";
import AuthApi from "../../api/AuthApi";
import { Button } from "../button/Button";

export function Header() {
  const { t } = useTranslation();

  const navigate = useNavigate();

  function logout() {
    AuthApi.logout();
    navigate(routes.login);
  }

  return (
    <div className="header-wrapper">
      <div className="header flex">
        <div className={"content flex"}>
          <Link to={routes.root} className={"logo"}>
            <img src={logo} alt={"logo"} className={"logo"} />
          </Link>
          <Link to={routes.items}>{t("header.items")}</Link>
          <Link to={routes.tours}>{t("header.tours")}</Link>
        </div>
        <Button onClick={logout}>{t("header.logout")}</Button>
      </div>
    </div>
  );
}
