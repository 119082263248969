import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { TourForm } from "../../components/tour-form/TourForm";
import { routes } from "../../constants/routes";
import TourApi from "../../api/TourApi";
import { BackButton } from "../../components/back-button/BackButton";

export function CreateTour() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="create-tour-wrapper center-wrapper">
      <div className="create-tour page-width">
        <BackButton to={routes.tours} />
        <h1>{t("tour.newTour")}</h1>
        <TourForm
          onSubmit={async (newTour) => {
            try {
              await TourApi.createTour(newTour);
              toast.success(t("tour.created"));
              navigate(routes.tours);
            } catch (e) {
              toast.error(t("tour.failedToCreate"));
            }
          }}
        />
      </div>
    </div>
  );
}
