import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { routes } from "../../constants/routes";
import { ItemForm } from "../../components/item-form/ItemForm";
import ItemApi, { ItemResponse } from "../../api/ItemApi";
import { BackButton } from "../../components/back-button/BackButton";

export function EditItem() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [item, setItem] = useState<ItemResponse | null>(null);

  useEffect(() => {
    if (!id) {
      return;
    }

    ItemApi.getItem(id).then((item) => {
      setItem(item);
    });
  }, [id]);

  if (!id) {
    navigate(routes.items);
    return null;
  }

  return (
    <div className="edit-item-wrapper center-wrapper">
      <div className="edit-item page-width">
        <BackButton to={routes.items} />
        <h1>{t("item.edit")}</h1>
        <ItemForm
          item={item}
          onSubmit={async (newItem) => {
            try {
              await ItemApi.updateItem(newItem, id);
              toast.success(t("item.saved"));
              navigate(routes.items);
            } catch (e) {
              toast.error(t("item.failedToSave"));
            }
          }}
        />
      </div>
    </div>
  );
}
