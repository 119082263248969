import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import LanguageApi, { Language } from "../../api/LanguageApi";
import MediaApi from "../../api/MediaApi";
import {
  TourCreateOrUpdate,
  TourResponse,
  TourStatus,
} from "../../api/TourApi";
import { LanguageValue } from "../../types/LanguageValue";
import { getFilenameFromUrl, virtualFileFromUrl } from "../../util/files";
import { Button } from "../button/Button";
import { FileUpload } from "../file-upload/FileUpload";
import { Input } from "../input/Input";
import { LanguageSelect } from "../language-select/LanguageSelect";
import { LoadingSpinner } from "../loading-spinner/LoadingSpinner";
import "./TourForm.css";
import {
  CellClickedEvent,
  ColDef,
  GridApi,
  ValueGetterParams,
} from "ag-grid-community";
import ItemApi, {
  ItemResponse,
  MultimediaType,
  CropData,
} from "../../api/ItemApi";
import { AgGridReact } from "ag-grid-react";
import Dialog from "../dialog/Dialog";
import { FaCrop, FaPlus, FaTrash } from "react-icons/fa";
import { findLanguageValueWithDefaultLanguage } from "../findLanguageValueWithDefaultLanguage";
import { Textarea } from "../textarea/Textarea";
import { ImageCropperDialog } from "../image-cropper-dialog/ImageCropperDialog";
import { Select } from "../select/Select";
import { sortLanguages } from "../../util/orderLanguages";

type ImageFileData = {
  file: File;
  url: string;
  virtual: boolean;
  crop?: CropData;
};

export function TourForm({
  onSubmit,
  tour,
}: {
  onSubmit: (newTour: TourCreateOrUpdate) => void;
  tour?: TourResponse | null;
}) {
  const { t } = useTranslation();

  const [currentLanguage, setCurrentLanguage] = useState<Language | null>(null);
  const [languages, setLanguages] = useState<Language[]>([]);
  const [selectedLanguages, setSelectedLanguages] = useState<Language[]>([]);

  const [title, setTitle] = useState<LanguageValue[]>([]);
  const [description, setDescription] = useState<LanguageValue[]>([]);
  const [imageFileData, setImageFileData] = useState<ImageFileData | null>(
    null
  );

  const [showLoading, setShowLoading] = useState(false);
  const [items, setItems] = useState<ItemResponse[]>([]);
  const [tourItems, setTourItems] = useState<ItemResponse[]>([]);
  const [tourStatus, setTourStatus] = useState<TourStatus>(TourStatus.DRAFT);

  const [showAddItemModal, setShowAddItemModal] = useState<boolean>(false);
  const [showCropModal, setShowCropModal] = useState<boolean>(false);

  useEffect(() => {
    LanguageApi.getLanguages().then((languages) => {
      const sortedLanguages = sortLanguages(languages);
      setLanguages(sortedLanguages);
      setSelectedLanguages(sortedLanguages);

      if (sortedLanguages.length > 0 && !tour) {
        setCurrentLanguage(sortedLanguages[0]);
        for (const language of sortedLanguages) {
          setTitle((title) => {
            title.push({
              languageId: language.id,
              value: "",
            });
            return title;
          });

          setDescription((description) => {
            description.push({
              languageId: language.id,
              value: "",
            });
            return description;
          });
        }
      }
    });

    ItemApi.listItems().then((d) => {
      setItems(d);

      if (tour) {
        const itemIds = tour.itemsWithOrder.map((el) => el.itemId);
        const tempItems = itemIds
          .map((id) => d.find((el) => el.id === id))
          .filter((el) => el !== undefined) as ItemResponse[];
        setTourItems(tempItems);
      }
    });
  }, [tour]);

  useEffect(() => {
    if (tour && languages.length > 0) {
      setImageFileData({
        url: tour.image.originalUrl,
        file: virtualFileFromUrl(tour.image.originalUrl),
        virtual: true,
        crop: {
          x: tour.image.cropX,
          y: tour.image.cropY,
          width: tour.image.cropWidth,
          height: tour.image.cropHeight,
          aspectRatio: 12 / 9,
        },
      });
      setTitle(tour.title);
      setDescription(tour.description);
      setTourStatus(tour.status);
      setSelectedLanguages(
        languages.filter((l) => tour.languages.some((el) => el.id === l.id))
      );
    }
  }, [tour, languages]);

  const tourItemsGridRef = useRef<GridApi<ItemResponse>>();
  const itemsGridRef = useRef<GridApi<ItemResponse>>();

  const columnDefs: ColDef<ItemResponse>[] = [
    {
      headerName: t("table.title"),
      valueGetter: (params: ValueGetterParams<ItemResponse, any>) => {
        return findLanguageValueWithDefaultLanguage(
          params.data?.title || [],
          languages
        );
      },
      sortable: false,
      filter: true,
      resizable: true,
      field: "title",
      rowDrag: true,
      flex: 1,
    },
    {
      headerName: t("table.artist"),
      valueGetter: (params) => params.data?.artist,
      sortable: false,
      filter: true,
      field: "artist",
      resizable: true,
      flex: 1,
    },
    {
      headerName: t("table.location"),
      valueGetter: (params: ValueGetterParams<ItemResponse, any>) => {
        return findLanguageValueWithDefaultLanguage(
          params.data?.location || [],
          languages
        );
      },
      sortable: false,
      filter: true,
      resizable: true,
      field: "location",
      flex: 1,
    },
    {
      headerName: t("table.description"),
      valueGetter: (params: ValueGetterParams<ItemResponse, any>) => {
        return findLanguageValueWithDefaultLanguage(
          params.data?.description || [],
          languages
        );
      },
      sortable: false,
      filter: true,
      resizable: true,
      flex: 1,
      field: "description",
    },
  ];

  const columnDefsAdd: ColDef<ItemResponse>[] = [
    ...columnDefs,
    {
      headerName: "",
      cellRenderer: () => {
        return <FaPlus className={"cell-operation"} />;
      },
      onCellClicked: (event: CellClickedEvent<ItemResponse, any>) => {
        if (event.data === undefined) {
          return;
        }
        const id: string = event.data.id;
        const itemToAdd = items.find((i) => i.id === id);
        if (itemToAdd === undefined) {
          return;
        }
        setTourItems([...tourItems, itemToAdd]);
        setItems(items.filter((e) => e.id !== id));
      },
      sortable: false,
      filter: false,
      resizable: false,
      flex: 0.2,
      field: "id",
    },
  ];

  const columnDefsDelete: ColDef<ItemResponse>[] = [
    ...columnDefs,
    {
      headerName: "",
      cellRenderer: () => {
        return <FaTrash className={"cell-operation"} />;
      },
      onCellClicked: (event: CellClickedEvent<ItemResponse, any>) => {
        if (event.data === undefined) {
          return;
        }
        const id: string = event.data.id;
        const item: ItemResponse | undefined = tourItems.find(
          (el) => el.id === id
        );
        if (!item) {
          return;
        }
        setTourItems(tourItems.filter((i) => i.id !== id));
        setItems([...items, item]);
      },
      sortable: false,
      filter: false,
      resizable: false,
      flex: 0.2,
      field: "id",
    },
  ];

  function allInputsHaveValue() {
    for (const language of languages) {
      for (const value of title) {
        if (value.languageId === language.id && !value.value) {
          return false;
        }
      }

      for (const value of description) {
        if (value.languageId === language.id && !value.value) {
          return false;
        }
      }
    }

    return true;
  }

  if (!currentLanguage) {
    return null;
  }

  return (
    <div className="center-wrapper">
      <div className={"tour-wrapper page-width"}>
        <Select
          label={"Tour status"}
          value={tourStatus}
          onChange={(val) => setTourStatus(val as TourStatus)}
          options={[
            { label: TourStatus.DRAFT, value: TourStatus.DRAFT },
            { label: TourStatus.PUBLISHED, value: TourStatus.PUBLISHED },
          ]}
        ></Select>
        <div className="languages-wrapper mt-2">
          <div className="languages-title">{t("tour.languages")}</div>
          {languages.map((language) => (
            <div key={language.id} className="item">
              <input
                className="form-check-input mr-2"
                type="checkbox"
                id={language.id}
                checked={selectedLanguages.includes(language)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedLanguages([...selectedLanguages, language]);
                  } else {
                    setSelectedLanguages(
                      selectedLanguages.filter((l) => l.id !== language.id)
                    );
                  }
                }}
              />
              <label className="form-check-label" htmlFor={language.id}>
                {language.name}
              </label>
            </div>
          ))}
        </div>
        <FileUpload
          className="mb-4"
          label="Image"
          required
          file={imageFileData?.file ?? null}
          cropData={imageFileData?.crop ?? undefined}
          initialFilename={
            tour ? getFilenameFromUrl(tour.image.originalUrl) : undefined
          }
          setFile={(file) => {
            setImageFileData({
              url: URL.createObjectURL(file),
              file,
              virtual: false,
              crop: undefined,
            });
          }}
          accept="image/*"
          preview={true}
          fileType={tour ? MultimediaType.IMAGE : null}
        >
          {tour || (imageFileData && !imageFileData.virtual) ? (
            // show crop button if tour is loaded on edit, or if real image file is selected (not virtual)
            <FaCrop
              className="crop-image mr-2 mt-2 cursor-pointer"
              onClick={() => setShowCropModal(true)}
            />
          ) : null}
        </FileUpload>
        <LanguageSelect
          languages={languages}
          currentLanguage={currentLanguage}
          setCurrentLanguage={setCurrentLanguage}
        />
        <form
          className="tour-form mt-4"
          onSubmit={async (e) => {
            e.preventDefault();

            if (!imageFileData) {
              // no image is selected
              toast.warning("Please select an image");
              return;
            }

            if (!allInputsHaveValue()) {
              toast.warning(t("general.notAllFields"));
              return;
            }

            let imageUrl: string;
            try {
              if (imageFileData?.virtual) {
                // file is still virtual (hasn't changed), so no need to reupload
                imageUrl = tour?.image.originalUrl ?? "";
              } else {
                setShowLoading(true);
                imageUrl = await MediaApi.uploadMedia(imageFileData.file);
                setShowLoading(false);
              }
            } catch (e) {
              toast.error(t("tour.failed"));
              setShowLoading(false);
              return;
            }

            if (onSubmit && tourItemsGridRef.current !== undefined) {
              const itemsWithOrder: { itemId: string; order: number }[] = [];
              tourItemsGridRef.current.forEachNode((node, i) => {
                if (!node.data) {
                  return;
                }

                const { id } = node.data;
                itemsWithOrder.push({ itemId: id, order: i });
              });

              onSubmit({
                image: {
                  url: imageUrl,
                  crop: imageFileData?.crop ?? undefined,
                },
                title,
                description,
                status: tourStatus,
                itemsWithOrder,
                languageIds: selectedLanguages.map((l) => l.id),
              });
            }
          }}
        >
          <Input
            className="mb-2"
            type="text"
            required
            value={
              title.find((t) => t.languageId === currentLanguage.id)?.value ??
              ""
            }
            placeholder={t("tour.title")}
            onChange={(value) => {
              const newTitle = title.filter(
                (t) => t.languageId !== currentLanguage.id
              );
              newTitle.push({
                languageId: currentLanguage.id,
                value,
              });
              setTitle(newTitle);
            }}
            showAsterisk
          />
          <Textarea
            className="mt-4"
            required
            value={
              description.find((t) => t.languageId === currentLanguage.id)
                ?.value ?? ""
            }
            placeholder={t("tour.description")}
            onChange={(value) => {
              const newDescription = description.filter(
                (t) => t.languageId !== currentLanguage.id
              );
              newDescription.push({
                languageId: currentLanguage.id,
                value,
              });
              setDescription(newDescription);
            }}
          />
          <div className={"items-table ag-theme-alpine"}>
            <Button
              type={"reset"}
              onClick={() => {
                setItems(
                  items.filter((i) => !tourItems.find((e) => e.id === i.id))
                );
                setShowAddItemModal(true);
              }}
            >
              {t("tour.addItems")}
            </Button>
            <AgGridReact
              rowData={tourItems}
              columnDefs={columnDefsDelete}
              onGridReady={(params) => {
                tourItemsGridRef.current = params.api;
              }}
              animateRows
              rowDragManaged
            />
          </div>
          <div className={"buttons"}>
            <Button type="submit" color="primary" className="mt-4">
              {t("general.save")}
            </Button>
          </div>
        </form>
        {showLoading && <LoadingSpinner />}
      </div>
      <Dialog
        isOpen={showAddItemModal}
        onRequestClose={() => setShowAddItemModal(false)}
        title={t("tour.addItems")}
        size="slide"
      >
        <div className={"add-item-dialog-content"}>
          <div className={"items-table ag-theme-alpine"}>
            <AgGridReact
              rowData={items}
              columnDefs={columnDefsAdd}
              onGridReady={(params) => {
                itemsGridRef.current = params.api;
              }}
              animateRows
              rowDragManaged
            />
          </div>
        </div>
      </Dialog>
      {imageFileData ? (
        <ImageCropperDialog
          isOpen={showCropModal}
          setIsOpen={setShowCropModal}
          imageUrl={imageFileData.url}
          initialCropData={imageFileData.crop}
          onSave={(crop) => {
            setImageFileData({
              url: imageFileData.url,
              file: imageFileData.file,
              virtual: imageFileData.virtual,
              crop: {
                x: crop.x,
                y: crop.y,
                width: crop.width,
                height: crop.height,
                aspectRatio: 12 / 9,
              },
            });
          }}
          aspectRatio={12 / 9}
        />
      ) : null}
    </div>
  );
}
