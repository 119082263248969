import React from "react";

function ImageCrop({
  imageUrl,
  cropWidth,
  cropHeight,
  cropX,
  cropY,
  aspectRatio,
}: {
  imageUrl: string;
  cropWidth: number;
  cropHeight: number;
  cropX: number;
  cropY: number;
  aspectRatio: number;
}) {
  // Calculate container dimensions
  const height = 80; // fixed height in pixels
  const width = height * aspectRatio;

  // Calculate background size
  const backgroundSizeX = 100 / (cropWidth / 100);
  const backgroundSizeY = 100 / (cropHeight / 100);
  const backgroundSize = `${backgroundSizeX}% ${backgroundSizeY}%`;

  // Calculate background position
  const backgroundPositionX = -cropX * (backgroundSizeX / 100);
  const backgroundPositionY = -cropY * (backgroundSizeY / 100);
  const backgroundPosition = `${backgroundPositionX}px ${backgroundPositionY}px`;

  // Styles for the container
  const containerStyles = {
    width: `${width}px`,
    height: `${height}px`,
    backgroundImage: `url(${imageUrl})`,
    backgroundSize: backgroundSize,
    backgroundPosition: backgroundPosition,
    backgroundRepeat: "no-repeat",
  };

  return <div style={containerStyles}></div>;
}

export default ImageCrop;
