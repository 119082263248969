import React from "react";
import "./Select.css";

export function Select({
  className,
  label,
  value,
  onChange,
  required = true,
  options,
  showAsterisk,
  clean = false,
}: {
  className?: string;
  label: string | null;
  value: string;
  required?: boolean;
  onChange: (value: string) => void;
  options: { label: string; value: string }[];
  showAsterisk?: boolean;
  clean?: boolean;
}) {
  return (
    <div className={`select-group flex ${clean ? "clean" : ""} ${className}`}>
      <select
        className={`select-field mt-2 ${clean ? "clean" : ""}`}
        value={value}
        required={required}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      >
        {options.map((option) => {
          return (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </select>
      {label ? (
        <label className="label">
          {label} {required && showAsterisk ? "*" : ""}
        </label>
      ) : null}
    </div>
  );
}
