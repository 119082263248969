import { LanguageValue } from "../types/LanguageValue";
import ApiInstance from "./ApiInstance";
import { Language } from "./LanguageApi";
import { Image } from "./MediaApi";

interface Item {
  title: LanguageValue[];
  description: LanguageValue[];
  location: LanguageValue[];
  thanksTo: LanguageValue[];
  artist: string;
}

export interface ItemCreateOrUpdate extends Item {
  multimedia: MultimediaCreateOrUpdate[];
}

export interface ItemWithOnlyId {
  id: string;
}

export interface ItemResponse extends ItemWithOnlyId, Item {
  multimedia: MultimediaResponse[];
  itemsWithOrder: ItemWithOrderResponse[];
}

export type ItemWithOrderResponse = {
  itemId: string;
  tourId: string;
  order: number;
};

export enum MultimediaType {
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
  AUDIO_FRAGMENT = "AUDIO_FRAGMENT",
}

interface Multimedia {
  type: MultimediaType;
  url: string;
  highlighted: boolean;
  order?: number;
}

export interface CropData {
  x: number;
  y: number;
  width: number;
  height: number;
  aspectRatio: number;
}

export interface MultimediaCreateOrUpdate extends Multimedia {
  languageIds: string[];
  crop?: CropData;
}

export interface MultimediaResponse extends Multimedia {
  languages: Language[];
  image: Image | null;
}

class ItemApi {
  public async getItem(id: string): Promise<ItemResponse> {
    const response = await ApiInstance.getApi().get(`/items/${id}`);
    return response.data;
  }

  public async createItem(newItem: ItemCreateOrUpdate): Promise<ItemResponse> {
    const response = await ApiInstance.getApi().post("/items", newItem);
    return response.data;
  }

  public async updateItem(
    item: ItemCreateOrUpdate,
    id: string
  ): Promise<ItemResponse> {
    const response = await ApiInstance.getApi().put(`/items/${id}`, item);
    return response.data;
  }

  public async listItems(): Promise<ItemResponse[]> {
    const response = await ApiInstance.getApi().get("/items");
    return response.data.data;
  }

  public async deleteItem(id: string): Promise<void> {
    await ApiInstance.getApi().delete(`/items/${id}`);
  }
}

export default new ItemApi();
