import React, { HTMLInputTypeAttribute, useRef } from "react";
import "./Input.css";
import hidePassword from "../../assets/hidePassword.svg";
import showPassword from "../../assets/showPassword.svg";

export function Input({
  className,
  value,
  onChange,
  placeholder,
  required = false,
  type,
  image,
  alt,
  disabled,
  showPasswordIcon = false,
  isRevealedPassword,
  setIsRevealedPassword,
  minLength,
  maxLength,
  min,
  max,
  readOnly,
  step,
  showAsterisk,
  form,
}: {
  className?: string;
  value: string;
  onChange: (v: string) => void;
  placeholder: string;
  required?: boolean;
  type: HTMLInputTypeAttribute;
  image?: string;
  alt?: string;
  disabled?: boolean;
  showPasswordIcon?: boolean;
  isRevealedPassword?: boolean;
  setIsRevealedPassword?: (v: boolean) => void;
  minLength?: number;
  maxLength?: number;
  min?: number;
  max?: number;
  readOnly?: boolean;
  step?: number;
  showAsterisk?: boolean;
  form?: string;
}) {
  const ref = useRef<HTMLInputElement>(null);

  return (
    <div className="input-with-img">
      {image ? <img className="input-img" src={image} alt={alt} /> : null}
      <input
        ref={ref}
        className={`form-field ${className ? className : ""} ${
          image ? "image" : ""
        }`}
        placeholder={placeholder}
        type={type}
        value={value}
        required={required}
        onChange={(e) => onChange(e.target.value)}
        disabled={disabled}
        minLength={minLength}
        maxLength={maxLength}
        min={min}
        max={max}
        readOnly={readOnly}
        step={step}
        form={form}
      />
      {showPasswordIcon ? (
        <img
          src={isRevealedPassword ? showPassword : hidePassword}
          className={"input-eye pointer"}
          alt={"show or hide password icon"}
          onClick={() => {
            if (!setIsRevealedPassword) {
              return;
            }

            setIsRevealedPassword(!isRevealedPassword);
          }}
        />
      ) : null}
      <label
        className={`label ${image ? "with-img" : ""}`}
        onClick={() => {
          if (ref.current) {
            ref.current.focus();
          }
        }}
      >
        {placeholder} {showAsterisk && required ? "*" : ""}
      </label>
    </div>
  );
}
