import React, { useEffect, useRef, useState } from "react";
import "./Tours.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/button/Button";
import TourApi, { TourResponse } from "../../api/TourApi";
import LanguageApi, { Language } from "../../api/LanguageApi";
import { AgGridReact } from "ag-grid-react";
import {
  ColDef,
  ValueGetterParams,
  GridApi,
  CellClickedEvent,
} from "ag-grid-community";
import { routes } from "../../constants/routes";
import { toast } from "react-toastify";
import { FaPencilAlt, FaTrash } from "react-icons/fa";
import Dialog from "../../components/dialog/Dialog";
import { findLanguageValueWithDefaultLanguage } from "../../components/findLanguageValueWithDefaultLanguage";

export function Tours() {
  const { t } = useTranslation();

  const [languages, setLanguages] = useState<Language[]>([]);
  const [tours, setTours] = useState<TourResponse[]>([]);

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [deletingTourId, setDeletingTourId] = useState<string>("");

  const navigate = useNavigate();

  useEffect(() => {
    LanguageApi.getLanguages().then((l) => setLanguages(l));
    TourApi.listTours().then((t) => {
      setTours(t);
    });
  }, []);

  const toursGridRef = useRef<GridApi<TourResponse>>();

  const columnDefs: ColDef<TourResponse>[] = [
    {
      headerName: t("table.title"),
      valueGetter: (params: ValueGetterParams<TourResponse, any>) => {
        return findLanguageValueWithDefaultLanguage(
          params.data?.title || [],
          languages
        );
      },
      field: "title",
      sortable: false,
      filter: true,
      resizable: true,
      rowDrag: true,
      flex: 1,
    },
    {
      field: "status",
      headerName: t("table.status"),
      valueGetter: (params) => params.data?.status,
      sortable: false,
      filter: true,
      resizable: false,
      flex: 1,
    },
    {
      headerName: t("table.description"),
      valueGetter: (params: ValueGetterParams<TourResponse, any>) => {
        return findLanguageValueWithDefaultLanguage(
          params.data?.description || [],
          languages
        );
      },
      field: "description",
      sortable: false,
      filter: true,
      resizable: true,
      flex: 1,
    },
    {
      cellRenderer: () => {
        return <FaPencilAlt className={"cell-operation"} />;
      },
      flex: 0.2,
      sortable: false,
      filter: false,
      resizable: false,
      field: "id",
      headerName: "",
      onCellClicked: (event: CellClickedEvent<TourResponse, any>) => {
        if (event.data === undefined) {
          return;
        }
        navigate(routes.editTour.replace(":id", event.data.id || ""));
      },
    },
    {
      cellRenderer: () => {
        return <FaTrash color={"red"} className={"cell-operation"} />;
      },
      flex: 0.2,
      sortable: false,
      filter: false,
      resizable: false,
      field: "id",
      headerName: "",
      onCellClicked: (event: CellClickedEvent<TourResponse, any>) => {
        if (event.data === undefined) {
          return;
        }
        setDeletingTourId(event.data.id);
        setShowDeleteModal(true);
      },
    },
  ];

  function updateTourOrders() {
    if (toursGridRef.current === undefined) {
      return;
    }

    const items: { tourId: string; order: number }[] = [];
    toursGridRef.current.forEachNode((node, i) => {
      if (!node.data) {
        return;
      }

      const { id } = node.data;
      items.push({ tourId: id, order: i });
    });
    TourApi.updateTourOrders({ items: items })
      .then(() => toast.success(t("tours.orderUpdated")))
      .catch(() => {
        toast.error("general.somethingWrong");
      });
  }

  function deleteTour() {
    TourApi.deleteTour(deletingTourId)
      .then(() => {
        setTours(tours.filter((t) => t.id !== deletingTourId));
      })
      .catch(() => toast.error("general.somethingWrong"));
  }

  return (
    <div className={"tours"}>
      <div className={"tours-wrapper"}>
        <h1>{t("tours.title")}</h1>
        <div className={"table-header"}>
          <Button color={"secondary"} onClick={updateTourOrders}>
            {t("tours.updateOrder")}
          </Button>
          <Button
            onClick={() => {
              navigate(routes.newTour);
            }}
          >
            {t("tours.create")}
          </Button>
        </div>
        <div>
          {tours ? (
            <div className={"tours-table ag-theme-alpine"}>
              <AgGridReact
                rowData={tours}
                columnDefs={columnDefs}
                onGridReady={(params) => {
                  toursGridRef.current = params.api;
                }}
                animateRows
                rowDragManaged
              />
            </div>
          ) : null}
        </div>
      </div>
      <Dialog
        isOpen={showDeleteModal}
        onRequestClose={() => setShowDeleteModal(false)}
        title={t("tours.delete")}
        size="xsmall"
      >
        <div className={"delete-dialog-content"}>
          <div className={"description"}> {t("tours.deleteWarning")}</div>
          <div className={"button-wrapper"}>
            <Button
              color="danger"
              onClick={() => {
                deleteTour();
                setShowDeleteModal(false);
              }}
            >
              {t("general.confirm")}
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
