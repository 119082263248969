import ApiInstance from "./ApiInstance";

export interface Image {
  id: string;
  originalUrl: string;
  croppedUrl?: string;
  cropX: number;
  cropY: number;
  cropWidth: number;
  cropHeight: number;
}

class MediaApi {
  private createFormData(file: File): FormData {
    const formData = new FormData();
    formData.append("file", file);
    return formData;
  }

  public async uploadMedia(file: File): Promise<string> {
    const formData = this.createFormData(file);
    const response = await ApiInstance.getApi().post("/media", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data.url;
  }

  public async getImage(imageId: string): Promise<Image> {
    const response = await ApiInstance.getApi().get(`/media/images/${imageId}`);

    return response.data;
  }

  public async updateImageCrop(
    imageId: string,
    cropX: number,
    cropY: number,
    cropWidth: number,
    cropHeight: number
  ): Promise<Image> {
    const response = await ApiInstance.getApi().patch(
      `/media/images/${imageId}/crop`,
      {
        cropX,
        cropY,
        cropWidth,
        cropHeight,
      }
    );

    return response.data;
  }
}

export default new MediaApi();
